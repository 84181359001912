import { env, BARLY_URL, JUMPTRAK_URL } from './const.js'

export class Api {
  getEnv() {
    return env;
  }

  isDev() {
    return env.NAME == "dev" || env.NAME == "local"
  }

  getToken() {
    return localStorage.getItem("barly-token")
  }

  async fetchBlob(url, options = {}) {
    if (!options['headers']) options.headers = {}
    if (url.startsWith(BARLY_URL)) {
      options.headers['X-Parse-Session-Token'] = this.getToken()
    }

    if (url.startsWith(JUMPTRAK_URL)) {
      options.headers['X-Parse-Session-Token'] = this.getToken()
    }

    let ret = await fetch(url, options)
    if (ret.status == 200) {
      return await ret.blob()
    } else {
      throw new Error("Invalid response from " + url + ": " + ret.statusText + " " + html)
    }
  }

  timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async fetchJson(url, options = {}) {
    if (window.app && app.progress) app.progress.show();
    if (location.hostname == "127.0.0.1") await this.timeout(2000)
    if (!options['headers']) options.headers = {}
    if (url.startsWith(BARLY_URL)) {
      options.headers['X-Parse-Session-Token'] = this.getToken()
    }

    if (url.startsWith(JUMPTRAK_URL)) {
      options.headers['X-Parse-Session-Token'] = this.getToken()
    }

    options.headers["Content-Type"] = "application/json";

    try {
      let ret = await fetch(url, options)
      if (ret.status == 200 && ret.headers.get("content-type").startsWith("application/json")) {
        let json = await ret.json()
        if (!json.success) {
          if (json.message &&
            (json.message.indexOf("BRL021E ") >= 0 ||
              json.message.indexOf("BRL022W ") >= 0)) {
            this.tokenExpired()
            return;
          } else if (!options.supressErrorMessage) {
            if (json.message) throw new Error(json.message)
            if (json.messages) throw new Error(json.messages.map(m => m.message).join("\n"))
            else throw new Error(JSON.stringify(json))
          }
        }
        return json
      } else {
        let html = await ret.text()
        if (ret.status == 200) {
          // check if we redirected to login page
          if (html.indexOf("<input type=\"password\"") > 0) {
            this.tokenExpired()
            return;
          } else {
            throw new Error("Invalid response from " + url + ": " + ret.statusText + " " + html)
          }
        } else if (ret.status == 401) {
          this.tokenExpired()
          return;
        } else {
          throw new Error("Invalid response from " + url + ": " + ret.statusText + " " + html)
        }
      }
    } finally {
      if (window.app && app.progress && app.autohideProgress != false) app.progress.hide();
    }
  }

  tokenExpired() {
    // token expired
    localStorage.removeItem("barly-token");
    localStorage.removeItem("whoAmI");
    alert("Your session has expired, please login again.");
    location.href = '/';
  }

  getMediaUrl(mediaRuid) {
    return BARLY_URL + "/api/media/get?ruid=" + mediaRuid
  }

  async getCountries() {
    return await this.fetchJson(`${BARLY_URL}/apx/country`)
  }

  async getProvinces(countryCode) {
    return await this.fetchJson(`${BARLY_URL}/apx/province?countryIsoCode=${countryCode}`)
  }

  async getOrgTypes() {
    return await this.fetchJson(`${BARLY_URL}/api/organisationtype`)
  }

  async getOrders(partyRuid, documentNumber) {
    return await this.fetchJson(`${BARLY_URL}/apx/po/get?forPartyRuid=${partyRuid}&document=${documentNumber}`)
  }

  async getPublishers() {
    return await this.fetchJson(BARLY_URL + "/apx/party/seller")
  }

  async getCurriculum() {
    return await this.fetchJson(BARLY_URL + "/apx/curriculum")
  }

  async getProductTypes() {
    return await this.fetchJson(BARLY_URL + "/apx/producttype")
  }

  async getSubTypes() {
    return await this.fetchJson(BARLY_URL + "/apx/product/subtype");
  }

  async getProductCapability(productIdentifier, capabilityCode) {
    return await this.fetchJson(BARLY_URL + "/apx/productcapability/get?productIdentifier=" +
      productIdentifier + "&capabilityCode=" + capabilityCode)
  }

  async getProductPrices(forProductCapability, buyOrganisationRuid) {
    return await this.fetchJson(BARLY_URL + "/apx/price/productcapability?forProductCapability=" + forProductCapability + "&forOrganisationRuid=" + (buyOrganisationRuid ? buyOrganisationRuid : ""))
  }

  async getProductCapabilityPrices(forProductCapabilities, quantities, forOrganisationRuid, currencyIsoCode, forCouponCodes) {
    let params = { forProductCapabilities, quantities, forOrganisationRuid, currencyIsoCode, forCouponCodes }
    return await this.fetchJson(BARLY_URL + "/apx/store/productcapability", {
      "body": JSON.stringify(params),
      "method": "POST",
    });
  }

  async getStoreBooks(searchText = "", productTypeRuid = [], publisherRuid = [], rating = 0, booktype = "", curriculum = "", grades = [], forOrganisationRuid = "", currencyIsoCode = "ZAR", page = 1) {
    let params = {
      "nameLike": searchText,
      "forProductTypeRuids": productTypeRuid,
      "forPartyOwnerRuids": publisherRuid,
      "rating": rating,
      "forCurriculumRuid": curriculum,
      "subType": booktype,
      "grades": grades,
      "forOrganisationRuid": forOrganisationRuid,
      "currencyIsoCode": currencyIsoCode,
      "page": page
    }

    return await this.fetchJson(BARLY_URL + "/apx/store/productcapability", {
      "body": JSON.stringify(params),
      "method": "POST",
    });

  }

  async getProducts() {
    return await this.fetchJson(BARLY_URL + "/apx/store/products", {
      "method": "POST",
      "body": JSON.stringify({
        "is_sellable": true
      })
    })
  }

  async whoAmI() {
    if (!this.cacheWhoAmI) {
      this.cacheWhoAmI = await this.fetchJson(BARLY_URL + "/api/whoami")
    }
    return this.cacheWhoAmI
  }

  async productCapabilities() {
    return await this.fetchJson(BARLY_URL + "/api/login/productcapability", {
      "method": "POST",
      "body": "{\"userid\":\"\",\"password\":\"\",\"owner\":\"\",\"product\":\"jumptrak.library.author\",\"appVersion\":\"0.0.1\"}",
    });
  }

  async getSubscriptions(orgRuid) {
    return await this.fetchJson(BARLY_URL + "/api/subscription/me?forOrganisation=" + orgRuid)
  }

  async getUsageRights(subscriptionId) {
    return await this.fetchJson(BARLY_URL + "/api/usageright?forSubscription=" + subscriptionId)
  }

  async getUsageRightsStock(productRuid, capabilityCode) {
    return await this.fetchJson(BARLY_URL + "/apx/usageright/stock?productRuid=" + productRuid + "&capabilityCode=" + capabilityCode)
  }

  async getNotYetAssigned(usageRightId, i = 0, page = 1) {
    return await this.fetchJson(BARLY_URL + "/api/user/usageright/nya?forUsageRight=" + usageRightId + "&i=" + i + "&page=" + page)
  }

  async getUsageRightsAssigned(usageRightId, i = 0, page = 1) {
    return await this.fetchJson(BARLY_URL + "/api/user/usageright/assigned?forUsageRight=" + usageRightId + "&i=" + i + "&page=" + page)
  }

  async getNamedUsageRightsCSV(usageRightId) {
    let ret = await fetch(BARLY_URL + "/nur/download?forUsageRight=" + usageRightId,
      { headers: { 'X-Parse-Session-Token': this.getToken() } })
    return await ret.text()
  }

  async getTasks() {
    return await this.fetchJson(BARLY_URL + "/api/task/me")
  }

  async getPurchaseOrders() {
    return await this.fetchJson(BARLY_URL + "/api/po/list")
  }

  async getCoupon(code) {
    return await this.fetchJson(BARLY_URL + "/api/coupon/get?code=" + code)
  }

  async orgList() {
    let orgs = await this.fetchJson(BARLY_URL + "/api/organisations/list?includeSubOrganisations=false")
    return {
      organisations: orgs.organisations.filter(o => o.parent == null)
    }
  }

  async personRoleLicence() {
    return await this.fetchJson(BARLY_URL + "/apx/person/role/licence")
  }

  async apxRegisterOrg(orgName, orgType, regNumber = "", taxNumber = "", province = "", countryIsoCode = "ZA") {
    let classes = []
    let staffClassName = "Staff"
    let learnerClassName = "Learners"

    // Customize org structure for Jumptrak Community orgs
    if (orgType.toLowerCase() == "jumptrak community") {
      staffClassName = "Purchasers"
      classes.push({
        "name": "Learners",
        "grade": 0
      });
    }

    let ret = await this.fetchJson(BARLY_URL + "/apx/store/organisation/register", {
      method: "POST",
      body: JSON.stringify({
        "name": orgName,
        "organisationType": orgType,
        "staffOrganisationName": staffClassName,
        "learnerOrganisationName": learnerClassName,
        "registrationNumber": regNumber,
        "taxNumber": taxNumber,
        "province": province,
        "countryIsoCode": countryIsoCode,
        //"classes": classes
      })
    })

    if (!ret.success) return ret;

    return await this.jtRegisterSchool("", orgName, regNumber, orgType, countryIsoCode, province, classes)
  }

  async apxLearnerAdd(forClass, learnerDetails) {
    return await this.fetchJson(JUMPTRAK_URL + "/apx/learner/add?forClass=" + forClass, {
      method: "post",
      body: JSON.stringify(learnerDetails)
    })
  }

  async apxClassAdd(name, forSchoolRuid, grade) {
    return await this.fetchJson(JUMPTRAK_URL + "/apx/class/add", {
        method: "post",
        body: JSON.stringify({
          name, forSchoolRuid, grade,
          isDeleted: false, kpiLanguage: "", ruid: ""
        })
      })
  }

  async apxAddSpares(orgRuid, subOrgName, roleTypeName, forClass, quantity) {
    return await this.fetchJson(
      `${JUMPTRAK_URL}/apx/learner/addSpares?forOrganisationRuid=${orgRuid}&subOrganisationName=${subOrgName}&orgRoleTypeName=${roleTypeName}&forClass=${forClass}&quantity=${quantity}`)
  }

  async addLicense(usageRightId, userId) {
    return await this.fetchJson(BARLY_URL + "/api/nur/add?forUsageRight=" + usageRightId, {
      method: "POST",
      body: JSON.stringify({
        "forUser": userId,
        "isActive": true
      })
    })
  }

  async removeLicense(namedUserRightId) {
    return await this.fetchJson(BARLY_URL + "/api/nur/delete?id=" + namedUserRightId)
  }

  async apxLicense(orgRuid, productCapability, licenseLearners = [], noLicenseLearners = [], countryIsoCode = "", schoolName = "", className = "") {
    return await this.fetchJson(BARLY_URL + "/apx/store/license", {
      method: "POST",
      body: JSON.stringify({
        orgRuid, productCapability,
        "license": licenseLearners ? licenseLearners : [],
        "noLicense": noLicenseLearners ? noLicenseLearners : [],
        countryIsoCode, schoolName, className
      })
    })
  }

  async getLicenceDetails(forClass, academicYear, forSubscriptions) {
    return await this.fetchJson(JUMPTRAK_URL + "/apx/class/apiStoreLicenceDetails",
      {
        method: "POST",
        body: JSON.stringify({
          forClass, academicYear, forSubscriptions
        })
      })
  }

  async getOrderTask(orderNumber) {
    return await this.fetchJson(BARLY_URL + "/api/task/get?name=" + orderNumber + "&taskType=apx.buy")
  }

  async cancelOrder(orderNumber) {
    return await this.fetchJson(BARLY_URL + "/apx/store/order/cancel?orderNumber=" + orderNumber)
  }

  async apxBuyPreflight(purchaser) {
    if (!purchaser || !purchaser.organisation || !purchaser.organisation.ruid) {
      throw Error("Organisation Ruid must be specified")
    }

    // Test apx.buy permissions and roles
    let me = await this.whoAmI()
    if (!me.person || !me.person.id) throw Error("Unable to verify Person details")

    let payload = {
      document: env.API_KEY_PREFIX + me.person.id + " Jumptrak Access",
      purchaser: purchaser
    }

    return await this.fetchJson(BARLY_URL + "/apx/store/buy/preflight", {
      method: "POST",
      body: JSON.stringify(payload),
      supressErrorMessage: true
    })
  }

  // Generate a Barly BuyRequest compatible JSON payload for apxBuy, apxCouponValidate, etc.
  async apxBuyRequest(documentNumber, purchaser = {}, products = [], couponCodes = []) {
    let me = await this.whoAmI()
    if (!me.person || !me.person.id) throw Error("Unable to verify Person details")

    let payload = {
      document: env.API_KEY_PREFIX + me.person.id + "-" + documentNumber,
      purchaser,
      products,
      couponCodes,
      learners: []
    }

    return payload;
  }

  async apxCouponValidate(buyRequest, options = {}) {
    return await this.fetchJson(BARLY_URL + "/apx/coupon/validate", {
      method: "POST",
      body: JSON.stringify(buyRequest),
      ...options
    })
  }

  async apxBuy(buyRequest, options = {}) {
    return await this.fetchJson(BARLY_URL + "/apx/buy/person", {
      method: "POST",
      body: JSON.stringify(buyRequest),
      ...options
    })
  }

  async doPayFastPayment(orderNumber, description, amount) {
    let baseUrl = location.protocol + "//" + location.host + "/"
    if (amount <= 0) {
      location.href = baseUrl + "#payment?order_number=" + encodeURIComponent(orderNumber)
      return
    }

    let ret = await this.fetchJson(BARLY_URL + "/api/payfast/url", {
      method: "post",
      body: JSON.stringify({
        document: orderNumber,
        name: orderNumber,
        description,
        amount: amount * 1.0,
        returnUrl: baseUrl + "#payment?order_number=" + encodeURIComponent(orderNumber),
        cancelUrl: baseUrl + "#orders"
      })
    })

    if (ret.method.toLowerCase() == "get") {
      let paymentUrl = ret.url + "?"
      for (let param of Object.entries(ret.parameters)) {
        paymentUrl += "&" + param[0] + "=" + param[1]
      }
      location.href = paymentUrl
      return
    }

    // submit a form with the URL and parameters
    var form = document.createElement("form");
    form.name = "payment";
    form.method = "POST";
    form.action = ret.url;

    for (let param of Object.entries(ret.parameters)) {
      var element = document.createElement("input");
      element.name = param[0];
      element.value = param[1];
      form.appendChild(element);
    }

    document.body.appendChild(form);
    form.submit();
  }

  async paymentConfirmation(orderNumber, callbackParams) {
    // call Barly with payment confirmation to kick-off the apx.buy task
    return await this.fetchJson(BARLY_URL + "/api/payfast/notify", {
      method: "POST",
      body: JSON.stringify({
        document: orderNumber,
        callbackParams
      })
    })
  }

  async jtRegisterSchool(adminUserId, orgName, orgRegNumber, orgTypeName, countryCode, province = "Other", classes) {
    let payload = {
      adminUserid: adminUserId,
      name: orgName,
      nationalNumber: orgRegNumber,
      organisationTypeName: orgTypeName,
      countryIsoCode: countryCode,
      provinceName: province,
      classes: classes
    }

    return await this.fetchJson(JUMPTRAK_URL + "/apx/school/register", {
      method: "POST",
      //headers: {
      //  "X-Api-Key": env.JT_APIKEY_SCHOOL_REG
      //},
      body: JSON.stringify(payload)
    })
  }

  async getLicenseDetails(nurId) {
    return await this.fetchJson(BARLY_URL + "/api/nur/combo?forNamedUserRight=" + nurId)
  }

  async jtSchoolList() {
    try {
      return await this.fetchJson(JUMPTRAK_URL + "/apx/school")
    } catch (e) {
      // Show no schools if JumpTrak throws a "Not authenticated"
      if (e.message.indexOf("JTK622W") >= 0) {
        return { "schools": [] }
      }

      throw e
    }
  }

  async jtClassList(schoolRuid) {
    return await this.fetchJson(JUMPTRAK_URL + "/apx/class?forSchoolRuid=" + schoolRuid)
  }

  async jtLearnerList(classId, year) {
    return await this.fetchJson(JUMPTRAK_URL + "/apx/store/learner?forClass=" + classId + "&academicYear=" + year)
  }

  async jtLicensees(classId, subscriptionId) {
    return await this.fetchJson(JUMPTRAK_URL + "/api/class/apiLicenceDetails?forClass=" + classId + "&forSubscription=" + subscriptionId)
  }

  async jtTeacherClasses() {
    return await this.fetchJson(JUMPTRAK_URL + "/api/class/3")
  }

  async jtStudentClasses() {
    return await this.fetchJson(JUMPTRAK_URL + "/apx/class/my")
  }

  /**
   * ===============================================================
   * Temporarily use localStorage to store order and purchase data
   * ===============================================================
   */

  saveOrder(order) {
    // Save the order into our history of orders
    let orders = []
    try {
      orders = JSON.parse(localStorage.getItem('orders')) || []
    } catch (e) {
      orders = []
    }
    orders.push(order)
    localStorage.setItem("orders", JSON.stringify(orders))
    localStorage.setItem("order", JSON.stringify(order))
  }

  getOrderDetails(orderNumber) {
    try {
      let orders = JSON.parse(localStorage.getItem("orders"))
      for (let order of orders) {
        if (order.doc == orderNumber) return order
      }
      return undefined
    } catch (e) {
      return undefined
    }

  }

  getOrder() {
    try {
      return JSON.parse(localStorage.getItem("order"))
    } catch (e) {
      return undefined
    }
  }

  getOrderHistory() {
    try {
      return JSON.parse(localStorage.getItem("orders")) || []
    } catch (e) {
      return []
    }
  }

  savePurchase(purchase) {
    localStorage.setItem("purchase", JSON.stringify(purchase))
  }

  getPurchase() {
    try {
      return JSON.parse(localStorage.getItem("purchase"))
    } catch (e) {
      return undefined
    }
  }
}
