import WebComponent from "../webcomponent.js";
import withOrderUtils from "../utils/order-utils.js";

const template = (obj) => html`
<link rel="stylesheet" href="css/app.css">
<section>
  <header>
    <h1>Order created</h1>
  </header>

  <main> 
    ${obj.order ? `
    <p>
    Your order has been created. You can review the order details below, then click the "Make payment" button to complete the order.
    </p>

    <p>
      <b>Order number:</b> ${obj.order.document}<br/>
    </p>

    <table>
      <thead>
        <th>Description</th>
        <th>Quantity</th>
        <th>Unit Price</th>
        <th>Total Price</th>
      </thead>
      <tbody>
        ${obj.renderOrderItems(obj.order)}
      </tbody>
      <tfoot>
        <tr>
          <td>Total price incl. VAT:</td> 
          <td></td>
          <td></td>
          <td align="right">
            ${obj.formattedPrice(obj.order, obj.order.amount)}
          </td>
        </tr>
      </tfoot>
    </table>

    <p>
      <ion-button id="btnPayment">Make payment</ion-button>
    </p>
    ` : `
    Unable to retrieve order details. Please go to <a href="javascript://" id="lnkOrders">My Orders</a> page to view the order and make payment.
    `}
  </main>
</section>
`

customElements.define('page-order-created', class extends withOrderUtils(WebComponent) {
  async init() {
    try {
      this.order = app.getOrderDetails()
      if (!this.order && location.hostname == "127.0.0.1") {
        // FOR DEV ONLY
        let task = (await app.api.getTasks()).tasks.pop()
        this.order = JSON.parse(task.packet)
        this.order.task = task
        this.order.userName = task.userName
        this.render()
      } else {
        let task = this.order.task
        let packet = JSON.parse(task.packet)
        if (packet.amount == undefined) throw Error("Amount not found in order packet")
        this.order = packet
        this.order.userName = task.userName
      }
    } catch (e) {
      console.log(e)
      this.order = undefined
    }
  }

  render() {
    this.shadow.innerHTML = template(this)

    let ordersPageLink = this.shadow.querySelector("#lnkOrders")
    if (ordersPageLink) ordersPageLink.addEventListener('click', () => {
      app.loadPage('orders')
    })

    let btnPayment = this.shadow.querySelector("#btnPayment")
    if (btnPayment) {
      if (this.order.amount == 0) btnPayment.innerHTML = "Continue"
      btnPayment.addEventListener('click', async () => {
        await app.api.doPayFastPayment(this.order.document, "JumpTrak Store Order " + this.order.document + " for " + this.order.userName, this.order.amount)
      })
    }
  }

})
