import { BARLY_URL } from "./const.js"
import { Api } from "./api.js"

export default class App {
  constructor() {
    this.cache = {} // cache that is cleared when the page is refreshed
    this.api = new Api()
    this.progress = document.getElementById("progress")
    this.toastComponent = document.getElementById("toast")
    this.menu = document.querySelector("ion-menu")
    this.cart = document.getElementsByTagName("app-cart")[0]
    this.currency = document.getElementsByTagName("app-currency")[0]
    this.currency.addEventListener('currency', (ev) => {
      if (this.currentPage().onCurrency) {
        this.currentPage().onCurrency(ev.detail)
      }
    })

    this.progress.show = () => this.progress.style.display = 'block';
    this.progress.hide = () => this.progress.style.display = 'none';

    this.toastComponent.addEventListener('didDismiss', () => {
      this.toastComponent.isOpen = false
    })

    // Replace default alert() with a themed dialog
    this.alertDialog = document.getElementById("alert")
    this.alert = (msg, header = "") => {
      this.alertDialog.header = header
      this.alertDialog.message = String(msg)
      this.alertDialog.buttons = ["Ok"]
      this.alertDialog.present()
    }
    window.alertOrg = window.alert
    window.alert = this.alert
  }

  confirm(message, onOk, onCancel) {
    let dlg = document.getElementById("alert")
    dlg.message = String(message)
    dlg.buttons = [{ text: "Cancel", role: "cancel" }, { text: "Ok", role: "ok" }]
    dlg.addEventListener('ionAlertDidDismiss', (ev) => {
      dlg.isOpen = false
      if (ev.detail.role == "ok" && onOk) onOk(); else if (onCancel) onCancel();
    })
    dlg.present()
  }

  login() {
    if (!this.isLoggedIn()) {
      location.href = BARLY_URL + "/login?app=" + this.api.getEnv().SSO_APPNAME + "&f=" +
        btoa(location.hash)
    } else {
      alert('Already logged in');
    }
  }

  isLoggedIn() {
    if (localStorage.getItem("barly-token")) {
      return true
    } else {
      return false
    }
  }

  currentPage() {
    let content = document.getElementById("content");
    return content.children[0]
  }

  logout() {
    localStorage.clear()
    location.href = BARLY_URL + "/logout?app=" + this.api.getEnv().SSO_APPNAME
  }

  openLibrary() {
    window.open(BARLY_URL + '/login?app=jumptrak&f=Lw==', '_blank')
  }

  async loadPage(page) {
    location.hash = page;
    app.menu.close()
  }

  getParams() {
    let params = new URLSearchParams(location.hash.split("?")[1])
    return params
  }

  setBuyOrganisation(organisationRuid) {
    this.buyOrganisationRuid = organisationRuid
    localStorage.setItem('buyOrganisationRuid', organisationRuid)
  }

  getBuyOrganisation() {
    if (!this.buyOrganisationRuid) this.buyOrganisationRuid = localStorage.getItem('buyOrganisationRuid')
    if (this.buyOrganisationRuid) return this.buyOrganisationRuid;
    return undefined
  }

  setOrderDetails(orderDetails) {
    this.orderDetails = orderDetails
  }

  getOrderDetails(orderDetails) {
    return this.orderDetails
  }

  toast(message) {
    if (!this.toastComponent) return;
    this.toastComponent.message = message
    this.toastComponent.isOpen = true
  }
}
