import WebComponent from '../webcomponent.js'
import withOrderUtils from "../utils/order-utils.js"

const template = (obj) => html`
<link rel="stylesheet" href="css/app.css">
<style>
  #order_success, #order_failed {
    display: none;
  }

  .row {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
  }

  .heading {
    flex-grow: 1;
  }

  section {
    min-width: 400px;
  }

  table {
    padding-left: 58px;
    width: 100%;
  }

  section {
    width: 100%;
    max-width: 60em;
  }

  summary {
    cursor: pointer;
    width: 100%;
    font-size: larger;
  }

  details {
    padding: 2em;
    margin: 6px;
  }

  ion-icon.status {
    width: 24px;
    height: 24px;
    margin: 0px 6px;
    fill: blue;
  }

  ion-card.message {
    padding: 2em;
    text-align: center;
  }
</style>
<section>
  <header>
    <h1>Orders</h1>
  </header>

<ion-segment id="filter" scrollable value="all">
  <ion-segment-button value="all">
    <ion-label>All</ion-label>
  </ion-segment-button>
  <ion-segment-button value="payment">
    <ion-label>Awaiting Payment</ion-label>
  </ion-segment-button>
  <ion-segment-button value="error">
    <ion-label>Error</ion-label>
  </ion-segment-button>
  <ion-segment-button value="completed">
    <ion-label>Completed</ion-label>
  </ion-segment-button>
</ion-segment>

  <main> 
  ${!obj.orders ? "<ion-card class='message'>Loading...</ion-card>" : obj.renderOrders(obj.orders)}
  </main>
</section>
`

const ordersTemplate = (obj, order) => html`
<ion-card>
<details>
  <summary order="${order.document}">
      <span style="position:absolute;width: 90%">
        <span style="display: flex; flex-direction: row; flex-wrap: wrap;">
          <ion-icon icon="${obj.statusIcon(order)}" style="fill: ${obj.statusColor(order)}" class="status"></ion-icon>
          <b>${order.document}</b>
          <span style="flex-grow: 1"></span>
          ${order.updated}
        </span>
      </span>
  </summary>
  <div class="row">
    <div class="heading">
      <b>Organisation:</b> ${obj.organisationName(order)}<br/>
      <b>Status:</b>
            ${order.isCompleted ? "Completed" : order.status}<br/>
    </div>
    <div class="retry">
      ${obj.isAwaitingPayment(order) ?
    `<ion-button class="retry" status="${order.status}" document="${order.document}">Pay</ion-button>
         <ion-button class="cancel" status="${order.status}" document="${order.document}">Cancel</ion-button>`
    : ""}
      ${obj.isError(order) ?
    `<ion-button class="retry" status="${order.status}" document="${order.document}">Retry</ion-button>`
    : ""}
    </div>
  </div>
  <br/>
  <table>
    <thead>
      <th>Description</th>
      <th>Quantity</th>
      <th>Unit Price</th>
      <th>Total Price</th>
    </thead>
    <tbody>
      ${obj.renderOrderItems(order)}
    </tbody>
    <tfoot>
      <tr>
        <td>Total price incl. VAT:</td> 
        <td></td>
        <td></td>
        <td align="right">${obj.formattedPrice(order, order.amount)}</td>
      </tr>
    </tfoot>
  </table>
</details>
</ion-card>
`

customElements.define("page-orders", class extends withOrderUtils(WebComponent) {
  async init() {
    // fail-safe json parser
    const jsonParse = (str) => {
      try {
        return JSON.parse(str ? str : "{ }")
      } catch (e) {
        return { error: e.message }
      }
    }

    let ret = await Promise.all([
      app.api.getTasks(),
      app.api.getPurchaseOrders()
    ])

    let purchaseOrders = ret[1].purchaseOrders
    // Get apx.buy tasks that have not been saved to purchase orders
    let orders = ret[0].tasks.filter(t => t.taskType == "apx.buy" &&
      purchaseOrders.findIndex(p => p.document == t.name) < 0).map(t => {
        return {
          "status": t.status,
          "updated": t.lastUpdateTimeFormatted.split(".")[0],
          "response": jsonParse(t.response),
          "isRunning": t.isRunning,
          "isCompleted": t.isCompleted,
          "percentageComplete": t.percentageComplete,
          ...jsonParse(t.packet)
        }
      })

    purchaseOrders.map(p => {
      // Convert purchase order result into task packet format for front-end to parse
      orders.push({
        "status": "Completed",
        "updated": p.lastUpdateTimeFormatted,
        "isRunning": false,
        "isCompleted": true,
        "percentageComplete": 100,
        "document": p.document,
        "currencyIsoCode": p.currencyIsoCode,
        "currencySymbol": p.currencySymbol,
        "amount": p.totalAmount,
        "organisationName": p.forOwnerOrganisation.name,
        "organisationRuid": p.forOwnerOrganisation.ruid,
        "products": p.items.map(i => {
          return {
            "identifier": i.productCapability.productIdentifier,
            "product": i.productCapability.name,
            "description": i.productCapability.name,
            "quantity": i.quantity,
            "amount": i.amount,
            "isTrial": i.isTrial,
            "trialDays": i.trialDays,
            "isBundle": i.childProductCapabilities && i.childProductCapabilities.length > 0,
            "childComponents": i.childProductCapabilities
          }
        })
      })
    })

    // Sort the order history
    this.orders = orders.toSorted((a,b) => {
      if (a.status != "Completed" && a.status != "Done") return -1;
      else if (b.status != "Completed" && b.status != "Done") return 1;
      else return (a.updated < b.updated ? 1 : -1)
    })

    this.render()
  }

  render() {
    this.shadow.innerHTML = template(this)
    this.handleRetryButtons()

    // order status filter 
    this.statusFilter = this.shadow.querySelector("#filter")
    this.statusFilter.addEventListener('ionChange', () => {
      this.shadow.querySelector("main").innerHTML = this.renderOrders(this.orders);
      this.handleRetryButtons()
    })

    // Keep polling if any orders are in progress
    if (this.orders) {
      let ordersInProgress = this.orders.filter(o => o.isRunning).length
      if (ordersInProgress) {
        // only poll while attached to the DOM
        if (this.parentElement) setTimeout(() => this.parentElement ? this.init() : "", 5000)
      }
    }
  }

  handleRetryButtons() {
    // Enable the retry buttons
    let btns = this.shadow.querySelectorAll("ion-button.retry")
    for (let b of btns) {
      b.addEventListener('click', () => {
        let doc = b.getAttribute("document")
        let status = b.getAttribute("status")
        if (status.toLowerCase() == "awaiting payment") {
          // user needs to make payment for this order
          let order = this.orders.filter(o => o.document == doc)[0]
          app.api.doPayFastPayment(order.document, "JumpTrak Store order " + order.document, order.amount)
        } else {
          // tell backend to try again
          app.loadPage("payment?order_number=" + doc)
        }
      })
    }

    // Enable cancel buttons
    btns = this.shadow.querySelectorAll("ion-button.cancel")
    for (let b of btns) {
      b.addEventListener('click', async () => {
        let doc = b.getAttribute("document")
        if (confirm("Are you sure you want to cancel this order?")) {
          let res = await app.api.cancelOrder(doc)
          if (res.success) {
            this.init()
          }
        }
      })
    }
  }

  renderOrders(orders) {
    if (!orders || !orders.length) return "<ion-card class='message'>No orders found</ion-card>"
    let ret = ""
    for (let o of orders) {
      if (this.statusMatch(o, this.statusFilter.value)) ret += ordersTemplate(this, o)
    }

    if (ret == "") ret = `<ion-card class="message">No orders found matching status '${this.statusFilter.value}'</ion-card>`

    return ret
  }
})
