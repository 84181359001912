import "../webcomponent.js"
import WebComponent from "../webcomponent.js"

const template = (obj) => html`
<link rel="stylesheet" href="/css/app.css">
<form style="display: flex; flex-direction: row; align-items:baseline">
  <label for="organisation">
    ${obj.getAttribute("label") || "Shopping for"} 
    &nbsp;
  </label>
  <select name="organisation" title="Pricing and product availability is affected by the organisation your are shopping for.">
    <option value="">Please select</option>
    ${obj.organisationOptions()}
  </select>
</form>

`

customElements.define('buy-organisations', class extends WebComponent {
  async init() {
    this.loadOrganisations()
    window.addEventListener("order-completed", (evt) => {
      app.cache.organisations = null
      this.loadOrganisations()
    })
  }

  get value() {
    return this.orgSelect.value == "" ? undefined : this.orgSelect.value
  }

  async loadOrganisations() {
    try {
      this.organisations = app.cache.organisations
      if (!this.organisations) {
        this.organisations = (await app.api.orgList()).organisations
        app.cache.organisations = this.organisations
      }
      this.render()
    } catch (e) {
      console.log(e)
      this.organisations = []
    }
  }

  render() {
    this.shadow.innerHTML = template(this)
    this.orgSelect = this.shadow.querySelector("select[name=organisation]")
    this.orgSelect.addEventListener('change', () => {
      app.setBuyOrganisation(this.orgSelect.value)
      this.dispatchEvent(new CustomEvent('change'))
    })

    if (this.organisations) {
      if (!app.getBuyOrganisation()) {
        app.setBuyOrganisation(this.orgSelect.value)
      } else if (!this.attributes['forceSelection'] || this.attributes['forceSelection'].value != "true") {
        this.orgSelect.value = app.getBuyOrganisation()
        if (this.orgSelect.value == "") {
          this.orgSelect.value = this.orgSelect.options[0].value
        }
      }
    }
  }

  organisationOptions() {
    function isMyUnit(organisation) {
      return organisation.organisationType.toLowerCase() == "jumptrak community"
    }

    let myUnits = this.organisations ? this.organisations.filter(o => isMyUnit(o)) : undefined
    let options = ''
    if (!myUnits || myUnits.length <= 0) {
      options += `<option value='me'>My Family</option>`
    } else {
      options += `<option value='${myUnits[0].ruid}'>My Family</option>`
    }
    options += `<option value="new-org">New organisation</option>`
    options += this.organisations && this.organisations.length > 0 ?
      this.organisations.sort((a, b) => a.fullName.localeCompare(b.fullName))
        .map((o) =>
          isMyUnit(o) ? '' : `<option value='${o.ruid}'>${sanitize(o.fullName)}</option>`)
      : ""

    return options
  }
})
