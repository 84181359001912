import WebComponent from '../webcomponent.js'

const template = (obj) => html`
<link rel="stylesheet" href="../../css/app.css">
<style>
  #order_success, #order_failed {
    display: none;
  }
  ion-progress-bar {
    margin: 0.5em 0;
  }
  ul {
    list-style-type: none;
  }
</style>
<section>
  <header>
    <h1>Payment processing</h1>
  </header>

  <main> 
    <div id="order_processing">
      <ion-icon class="medium-icon" name="time" style="fill:#1a77ba"></ion-icon> 
      <p>Your order <b>${obj.orderNumber}</b> is being processed.</p>
      <div id="order_status"></div>
    </div>

    <div id="order_success">
      <p>Your order <b>${obj.orderNumber}</b> has been processed.</p>
    </div>

    <div id="order_failed">
      <ion-icon class="medium-icon" name="alert-circle" style="fill:red"></ion-icon> 
      Processing order failed. Please try again or <a href="https://jumptrak.io/contact-us/" target="_blank">contact JumpTrak</a> with the following details:
      <ul>
        <li><b>Order number:</b> ${obj.orderNumber}</li>
        <li><b>Error:</b> <span id="error_message"></span></li>
      </ul>
      <ion-button id="linkRetry">Try again</ion-button>
    </div>
  </main>
</section>
`

const orderCompleted = (subscription, org) => html`
  <ion-icon class="medium-icon" name="checkmark-circle" style="fill:green"></ion-icon> 
  Order processing completed! 
  ${subscription ? `
  You can now manage licences for your products (you can also do this later from the <a href="#licenses">My Licences</a> page)
  <ul>
    ${(subscription.rights ? 
      subscription.rights.map(r => `<li><a href="#manage-licenses?org=${org}&p=${r.identifier}&c=${r.product}">${r.product}</a> (${r.quantity})</li>`).join("")
      : 
      subscription.usageRights.map(r => `<li><a href="#manage-licenses?org=${org}&p=${r.productIdentifier}&c=${r.name}">${r.name}</a> (${r.quantity})</li>`).join("")
      )}
  </ul>
  ` : `
  You can manage licences for your products from the <a href="#licenses">My Licences</a> page.
  `}
`

customElements.define("page-payment", class extends WebComponent {
  init() {
    this.orderNumber = app.getParams().get("order_number")
  }
  render() {
    this.shadow.innerHTML = template(this)

    this.shadow.querySelector("#linkRetry").onclick = () => {
      this.processOrder()
    }

    this.processOrder()
  }

  async processOrder() {
    this.shadow.querySelector("#order_processing").style.display = "block";
    this.shadow.querySelector("#order_failed").style.display = "none";
    this.shadow.querySelector("#order_success").style.display = "none";

    try {
      let ret = await this.doPurchase()
      if (ret.success) {
        if (ret.isComplete) {
          this.showSuccess(orderCompleted(ret.subscription, ret.ownerOrganisation.ruid))
          // send an event about the fact that an order was completed
          window.dispatchEvent(new CustomEvent('order-completed', {
            detail: ret
          }))
        } else {
          this.shadow.querySelector("#order_processing").style.display = "block";
          this.shadow.querySelector("#order_success").style.display = "none";
          this.pollOrderStatus()
        }
      }
    } catch (e) {
      this.showError(e);
    }
  }

  showSuccess(msg) {
    this.shadow.querySelector("#order_processing").style.display = "none";
    this.shadow.querySelector("#order_success").style.display = "block";
    this.shadow.querySelector("#order_failed").style.display = "none";
    this.shadow.querySelector("#order_success").innerHTML += msg;
  }

  showError(err) {
    this.shadow.querySelector("#order_processing").style.display = "none";
    this.shadow.querySelector("#order_success").style.display = "none";
    this.shadow.querySelector("#order_failed").style.display = "block";
    this.shadow.querySelector("#error_message").innerText = err;
    ;
  }

  async doPurchase() {
    return await app.api.paymentConfirmation(
      app.getParams().get("order_number"),
      location.search
    );
  }

  async pollOrderStatus() {
    if (!this.parentElement) return; // don't poll if we're detached from DOM
    let div = this.shadow.querySelector("#order_status")
    let res = await app.api.getOrderTask(this.orderNumber)
    if (res.task.status == "Awaiting payment" || res.task.status == "Payment received") {
      div.innerHTML = `<ul>
        <li> <b>Status:</b> ${res.task.status}
        <li> <b>Progress:</b> ${res.task.percentageComplete}%
          <ion-progress-bar value="${res.task.percentageComplete / 100}"></ion-progress-bar>
      </ul>
      `
      setTimeout(async () => {
        await this.pollOrderStatus()
      }, 5000)
    } else if (!res.task.isCompleted && res.task.isRunning) {
      div.innerHTML = `<ul>
        <li> <b>Status:</b> Order being processed
        <li> <b>Progress:</b> ${res.task.percentageComplete}%
          <ion-progress-bar value="${res.task.percentageComplete / 100}"></ion-progress-bar>
      </ul>`
      setTimeout(async () => {
        await this.pollOrderStatus()
      }, 5000)
    } else if (res.task.isCompleted && res.task.percentageComplete == 100) {
      // Done!
      try {
        let response = JSON.parse(res.task.response)
        let org = response.purchaser.organisation.ruid
        this.showSuccess(orderCompleted(response.subscription, org))
      } catch {
        // can't parse response
        this.showSuccess(orderCompleted())
      }
    } else {
      // task is not running and is not complete, however it could be quiesced or awaiting a run
      this.showError(res.task.status)
    }
  }
})
