import WebComponent from "../webcomponent.js"

const template = (obj) => html`
<style>
  ion-item {
    --inner-border-width: 0 0 0 0
  }
</style>

<ion-list>
  <ion-item button="true" id="mnuHome">
    <ion-icon aria-hidden="true" name="home" slot="start"></ion-icon>
    <ion-label>Shop</ion-label>
  </ion-item>
  ${app.isLoggedIn() ?
    html`
    <ion-item button="true" id="mnuLicenses">
      <ion-icon aria-hidden="true" name="receipt" slot="start"></ion-icon>
      <ion-label>My Licences</ion-label>
    </ion-item>
    <ion-item button="true" id="mnuOrders">
      <ion-icon aria-hidden="true" name="cart" slot="start"></ion-icon>
      <ion-label>My Orders</ion-label>
    </ion-item>
    <ion-item button="true" id="mnuJumptrak">
      <ion-icon aria-hidden="true" name="library" slot="start"></ion-icon>
      <ion-label>JumpTrak Apps</ion-label>
      <ion-icon name="open-outline"></ion-icon>
    </ion-item>
    <ion-item button="true" id="mnuFAQ">
      <ion-icon aria-hidden="true" name="information-circle" slot="start"></ion-icon>
      <ion-label>FAQs</ion-label>
    </ion-item>
    <ion-item button="true" id="mnuContact">
      <ion-icon aria-hidden="true" name="chatbubble-ellipses" slot="start"></ion-icon>
      <ion-label>Contact Us</ion-label>
    </ion-item>
    <ion-item button="true" id="mnuLogout">
      <ion-icon aria-hidden="true" name="log-out" slot="start"></ion-icon>
      <ion-label>Logout</ion-label>
    </ion-item>
      ${obj.isSystemAdmin ?
        html`
          <hr/>
          <ion-item button="true" id="mnuAdminPayment">
            <ion-icon aria-hidden="true" name="cash" slot="start"></ion-icon>
            <ion-label>Payment admin</ion-label>
          </ion-item>
        `
        : ``}
    `
    :
    `<ion-item button="true" id="mnuLogin">
      <ion-icon aria-hidden="true" name="log-in" slot="start"></ion-icon>
      <ion-label>Login / Register</ion-label>
    </ion-item>
    <ion-item button="true" id="mnuFAQ">
      <ion-icon aria-hidden="true" name="information-circle" slot="start"></ion-icon>
      <ion-label>FAQs</ion-label>
    </ion-item>
    <ion-item button="true" id="mnuContact">
      <ion-icon aria-hidden="true" name="chatbubble-ellipses" slot="start"></ion-icon>
      <ion-label>Contact Us</ion-label>
    </ion-item>`
  }
</ion-list>
`

customElements.define('menu-links', class extends WebComponent {
  init() {
    this.isSystemAdmin = false;
    window.addEventListener('whoami', (ev) => {
      if (ev && ev.detail) {
        this.isSystemAdmin = ev.detail.isSystemAdmin
        this.render()
      }
    })
  }

  render() {
    if (!window.app) {
      // app not yet initialized
      setTimeout(() => this.render(), 100)
      return;
    }

    this.shadow.innerHTML = template(this)
    this.shadow.querySelector("#mnuHome").addEventListener('click', () => app.loadPage('product-list'))
    this.shadow.querySelector("#mnuFAQ").addEventListener('click', () => app.loadPage('faq'))
    this.shadow.querySelector("#mnuContact").addEventListener('click', () => app.loadPage('contact'))
    if (app.isLoggedIn()) {
      this.shadow.querySelector("#mnuOrders").addEventListener('click', () => app.loadPage('orders'))
      this.shadow.querySelector("#mnuLicenses").addEventListener('click', () => app.loadPage('licenses'))
      this.shadow.querySelector("#mnuJumptrak").addEventListener('click', () => app.openLibrary())
      this.shadow.querySelector("#mnuLogout").addEventListener('click', () => app.logout())
      let adminPayment = this.shadow.querySelector("#mnuAdminPayment")
      if (adminPayment) adminPayment.addEventListener('click', () => app.loadPage('admin-payment'))
    } else {
      this.shadow.querySelector("#mnuLogin").addEventListener('click', () => app.login())
    }
  }
});
