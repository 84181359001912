
let env = {}

if (location.hostname == "localhost") {
  // use test.barly and test.jumptrak for local dev
  env = {
    NAME: "dev",
    BARLY_URL: "https://test.barly.co.za",
    JUMPTRAK_URL: "https://test.jumptrak.co.za",
    API_KEY_PREFIX: "ZZ-",
    DOC_PREFIX: "STO",
    SSO_APPNAME: "store.dev"
  }
} else if (location.hostname == "127.0.0.1") {
  // use local barly and local jumptrak for backend dev
  env = {
    NAME: "local",
    BARLY_URL: "http://localhost:7000",
    JUMPTRAK_URL: "http://localhost:9001",
    API_KEY_PREFIX: "ZZ-",
    DOC_PREFIX: "STO",
    SSO_APPNAME: "store"
  }
} else if (location.hostname == "test.store.jumptrak.co.za") {
  env = {
    NAME: "test",
    BARLY_URL: "https://test.barly.co.za",
    JUMPTRAK_URL: "https://test.jumptrak.co.za",
    API_KEY_PREFIX: "GB-",
    DOC_PREFIX: "STO",
    SSO_APPNAME: "store"
  }
} else if (location.hostname == "qa.store.jumptrak.co.za") {
  env = {
    NAME: "qa",
    BARLY_URL: "https://barly.co.za",
    JUMPTRAK_URL: "https://jumptrak.co.za",
    API_KEY_PREFIX: "GB-",
    DOC_PREFIX: "STO",
    SSO_APPNAME: "store.qa"
  }
} else {
  env = {
    NAME: "prod",
    BARLY_URL: "https://barly.co.za",
    JUMPTRAK_URL: "https://jumptrak.co.za",
    API_KEY_PREFIX: "GB-",
    DOC_PREFIX: "STO",
    SSO_APPNAME: "store"
  }
}

const BARLY_URL = env.BARLY_URL
const JUMPTRAK_URL = env.JUMPTRAK_URL

export {
  env, BARLY_URL, JUMPTRAK_URL
}
